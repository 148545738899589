<template>
  <div class="showcase">
    <v-card class="mx-auto" max-width="640">
      <v-card-title class="text--primary pb-0">
        {{ isCreate ? "Добавление" : "Редактирование" }} витрины
      </v-card-title>
      <v-card-text class="text--primary">
        <v-form ref="form">
          <v-text-field
            label="Название"
            v-model="formData.title"
            :error-messages="errors.title"
          ></v-text-field>
          <v-text-field
            label="Ссылка"
            v-model="formData.link"
            :error-messages="errors.link"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="orange" :to="showcasesRoute" text> Отмена </v-btn>
        <v-btn text @click="submit">
          {{ isCreate ? "Создать" : "Сохранить" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("showcases");

export default {
  name: "Showcase",
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: {},
      formData: {},
      showcasesRoute: { name: "showcases" },
    };
  },
  mounted() {
    this.formData = { ...this.$route.meta.formData };
  },
  methods: {
    ...mapActions(["getList", "read", "update", "create"]),
    submit() {
      this[this.isCreate ? "_createShowCase" : "_updateShowCase"](this.formData)
        .then(() => {
          this.$router.push(this.showcasesRoute);
        })
        .catch((e) => {
          this.errors = Object.keys(e.errors || []).reduce((acc, key) => {
            acc[key] = e.errors[key][0];
            if (acc[key] === "validation.required") {
              acc[key] = "Поле обязательное";
            }
            return acc;
          }, {});
        });
    },
    _updateShowCase(formData) {
      return this.update(formData);
    },
    _createShowCase(formData) {
      return this.create(formData);
    },
  },
};
</script>
